import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Navigation, Autoplay, EffectCoverflow } from 'swiper/modules';
import SectionHeading from '../Common/SectionHeading';
import Image from 'next/image';
import CommonPopup from '../PopupForm/CommonPopup';
import * as Yup from 'yup';


const DownloadableData = [
    {
        name: "image 1",
        imagSrc: "img/events/jpg/asco-2023/Asco-2023-preview-abstract.png"
    },
    {
        name: "image 1",
        imagSrc: "img/events/jpg/asco-2023/ASCO 2023-conference-coverage.png"
    },
    {
        name: "image 1",
        imagSrc: "img/events/jpg/asco-2023/Top-10-Hematological-Malignancies-Abstracts.png"
    },
    {
        name: "image 1",
        imagSrc: "img/events/jpg/asco-2023/Top-10-Breast-Cancer-Abstracts.png"
    },
    {
        name: "image 1",
        imagSrc: "img/events/jpg/asco-2023/Hematological-Malignancies-Abstract.png"
    }
]
const Downloadable = ({ title = null, data=null, year }) => {

    const [DataRendered, setDataRendered] = useState(false);
    useEffect(() => {
        setDataRendered(true)
    }, [])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const options = {
        btn_name: "Submit"
    }

    const phoneRegExp = /^(\+\d{1,4})?(\d{9,14})$/

    const fields = [
        {
            name: 'name',
            label: '',
            placeholder: 'Full Name*',
            validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
            className: 'block text-gray-700 w-full h-auto py-3 border focus:outline-none focus-visible:border border-gray-300 px-4 bg-transparent',
            customClass: 'md:col-span-6 col-span-12'
        },
        {
            name: 'email',
            type: 'email',
            label: '',
            placeholder: 'Add Corporate E-mail ID*',
            validation: Yup.string().email('Invalid email').required('Email is required'),
            className: 'block text-gray-700 w-full h-auto py-3 border focus:outline-none focus-visible:border border-gray-300 px-4 bg-transparent',
            customClass: 'md:col-span-6 col-span-12'

        },
    ];
    return (
        <>
            <div className="w-11/12 xl:max-w-screen max-w-screen-2xl mx-auto lg:mt-6  mb-20">
                <SectionHeading title={title} customClass="text-center" />
                <Swiper
                    modules={[Navigation, Autoplay, EffectCoverflow]}
                    speed={300}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    coverflowEffect={{
                        rotate: 30,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    }}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    breakpoints={{
                        680: {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 10,
                        },
                    }}
                    className="mySwiper"
                >
                    {DownloadableData?.map((item, i) => (
                        <SwiperSlide key={i}>
                            <>
                                <Image
                                    className='w-full h-full'
                                    priority={true}
                                    src={process.env.IMG_CDN + item?.imagSrc}
                                    alt={item?.name}
                                    width={2000}
                                    height={2000}
                                    onClick={openModal}
                                />
                            </>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <CommonPopup 
                    isOpen={isModalOpen} 
                    closeModal={closeModal}
                    fields={fields}
                    options={options}
                    page_name="Provide details to download"
                    form_name="downloadable-content-asco-conference"
                    formTitle="Provide details to download"
                    conference_name={"ASCO Conference" + " " + year}
                    endpoint='/enquiry/event-form'
                />
            </div>
        </>
    )
}
export default Downloadable;


