import React from 'react'
import SectionHeading from '../Common/SectionHeading';
import CallBackForm from '../Common/CallBackForm';
import Image from 'next/image';
import * as Yup from 'yup';

const ScheduleAppointment = ({title=null, shortDescription=null}) => {
    const options = {
        btn_name: "Request An Appointment"
      }
    
      const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    
      const fields = [
        {
          name: 'name',
          placeholder: 'Name',
          validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
          className: 'block text-gray-700 w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
          customClass: 'col-span-6'
        },
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email',
          validation: Yup.string().email('Invalid email').required('Email is required'),
          className: 'block text-gray-700 w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
          customClass: 'col-span-6'
        },
        {
          name: 'phone',
          placeholder: 'Phone',
          validation: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
          className: 'block text-gray-700 w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
          customClass: 'col-span-6'
        },
        {
          name: 'company_name',
          placeholder: 'Company',
          type: 'text',
          className: 'block text-gray-700 w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
          customClass: 'col-span-6'
        },
        {
          name: 'message',
          placeholder: 'Message',
          type: 'textarea',
          className: 'block text-gray-700 w-full py-2 border-b focus:outline-none focus-visible:border-b border-gray-300 px-4 bg-transparent',
          customClass: 'col-span-12'
        },
      ];
    return (
        <div className='' style={{backgroundImage: `url(${process.env.IMG_CDN+'img/events/jpg/esmo-2022/Book-your-appointment1.jpg'})`}}>
            <div className="w-11/12 py-6 mx-auto xl:max-w-screen max-w-screen-2xl lg:py-12">
                <div className='grid grid-cols-12 gap-4'>
                    <div className='col-span-12 lg:col-span-6'>
                        <p className='text-base text-white'>
                            At DelveInsight, we are dedicated to providing comprehensive solutions that accelerate our clients&apos; business growth and improve the healthcare industry. Our reputation as a leading partner in the expansion of various healthcare sectors is built on our expert consulting services and strategic foresight.
                        </p>
                        <p className='text-base text-white'>
                            With a team of experienced professionals, including seasoned consultants and key opinion leaders (KOLs), we are equipped to tackle any complex challenge.
                        </p>
                        <div className="flex items-start pb-3">
                            <div className="mr-4">
                                <div className="flex items-center justify-center text-white rounded-circle" style={{ height: '70px', width: '70px', marginTop: '15px' }}>
                                <Image 
                                    priority={false} 
                                    className="rounded-full border-[1px] p-2" 
                                    width={2000} 
                                    height={2000} 
                                    src={process.env.IMG_CDN+'img/events/conference/diverse-array-of-solutions.svg'} 
                                    alt="Design" 
                                />
                                </div>
                            </div>
                            <div className="">
                                <h4 className="text-lg font-semibold text-white">Diverse Array Of Solutions</h4>
                                <p className="text-base text-white">We specialize in providing customized solutions, carefully tailored to meet the specific needs of our clients.</p>
                            </div>
                        </div>
                        <div className="flex items-start">
                            <div className="mr-4">
                                <div className="flex items-center justify-center text-white rounded-circle" style={{ height: '70px', width: '70px', marginTop: '15px' }}>
                                <Image priority={false} className="rounded-full border-[1px] p-2" width={2000} height={2000} src={process.env.IMG_CDN+'img/events/conference/in-depth-analysis.svg'} alt="Design" />
                                </div>
                            </div>
                            <div className="">
                                <h4 className="text-lg font-semibold text-white">In-Depth Analysis</h4>
                                <p className="text-base text-white">Our team has deep expertise in the healthcare industry. We provide efficient, dynamic, and innovative insights, along with indication-specific reports and services.</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-12 lg:col-span-6'>
                        <div className='py-10 bg-white'>
                            <div className="bg-gray">
                                <SectionHeading title={title} shortDescription={shortDescription} customClass='text-center' />
                            </div>
                            <p className='px-10 text-base'>Access the most effective business solutions for the oncology industry by connecting with the premier consultancy and forecasting service provider.</p>
                            <div className="px-10">
                                <CallBackForm
                                    fields={fields}
                                    options={options}
                                    page_name="ESMO Page"
                                    form_name="esmo-landing-form"
                                    endpoint="/enquiry/conference-appointment"
                                />
                            </div>
                        </div>
                        
                    </div>
                </div>
                
            </div>
        </div>
    )
}
export default ScheduleAppointment;
