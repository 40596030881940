import React from 'react';
import { limitText } from "../Common/Functions";
import Link from 'next/link';
import BorderBtn from '../Common/BorderBtn';
import Image from 'next/image';

const Featured = ({ featuredData, year, slugPrefix, event, customClass }) => {
  return (
    <div className="w-11/12 mx-auto my-8 xl:max-w-screen max-w-screen-2xl">
      <div className='grid grid-cols-12 gap-4'>
        {featuredData?.map((card, index) => (
          <div key={index} className='col-span-12 lg:col-span-4'>
            <div className={"group object-cover relative overflow-hidden transition-all duration-500 mb-0 cursor-pointer before:absolute before:left-0 before:bottom-0 before:w-full before:h-full before:bg-gradient-to-b before:from-black before:via-opacity-0 before:to-transparent before:transform before:-rotate-180 before:transition-all before:duration-500 before:before:rounded-lg" + customClass}>
              <div className="relative overflow-hidden">
                <Image priority={true}
                  width={2000}
                  height={2000}
                  className={`w-full h-80 lg:h-96 object-cover group-hover:scale-110 transition-all duration-500 group-hover:duration-500`}
                  alt={card?.image_alt}
                  src={process.env.IMG_CDN + card?.image}
                />
                <div className="absolute inset-0 transition-opacity duration-300 bg-black rounded opacity-50 group-hover:opacity-80"></div>
              </div>
              <div className="absolute block left-0 bottom-[60px] overflow-hidden pl-4 pr-10 lg:group-hover:bottom-[180px] group-hover:bottom-[120px] transition-all duration-500 group-hover:transition-all group-hover:duration-500 group-hover:delay-300">
                {
                  event === '/asco' ?
                    <Link href={process.env.APP_URL + "/asco-conference" + "/article/" + card?.slug}>
                      <h3 className="my-4 text-xl font-semibold text-white">{card?.title}</h3>
                    </Link>
                    :
                    <Link href={process.env.APP_URL + "/" + slugPrefix + "/article/" + card?.slug}>
                      <h3 className="my-4 text-xl font-semibold text-white">{card?.title}</h3>
                    </Link>
                }
              </div>
              <div className="bg-[#ffffff33]  w-full absolute block left-0 bottom-0 z-10">
                {
                  event === '/asco' ?
                    <BorderBtn
                      customClass='w-full py-1 bg-[#1b5df3] border-[#1b5df3] text-white rounded'
                      buttonTitle="Go to Article"
                      buttonLink={process.env.APP_URL + "/asco-conference" + "/article/" + card?.slug}
                    /> :
                    <BorderBtn
                      customClass='w-full py-1 bg-[#1b5df3] border-[#1b5df3] text-white rounded'
                      buttonTitle="Go to Article"
                      buttonLink={process.env.APP_URL + "/" + slugPrefix + "/article/" + card?.slug}
                    />
                }
              </div>
              <div className="absolute lg:block hidden left-0 bottom-[-180px] px-5 group-hover:bottom-[100px] transition-all duration-500 group-hover:transition-all group-hover:duration-500 group-hover:delay-300">
                <p className="relative m-0 text-white line-clamp-3">
                  {card?.short_description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='my-4 text-center'>
        <Link href={process.env.APP_URL + ((year == 2020)
          ? ((slugPrefix.split('-')[0] === 'asco') ?
            `/${slugPrefix}/all` :
            ((slugPrefix.split('-').includes(year)) ?
              `/${slugPrefix}/all` :
              `/${slugPrefix}-${year}/all`))
          : (((slugPrefix.split('-').includes(year)) ?
            `/${slugPrefix}/all` :
            `/${slugPrefix}-${year}/all`)))}>
          <button className='px-6 py-1 font-semibold text-white bg-red-600 rounded '>VIEW ALL</button>
        </Link>
      </div>
    </div>
  );
};

export default Featured;