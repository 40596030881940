import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import Navbar from "../components/Layouts/Navbar";
import Head from 'next/head';
import Link from 'next/link'
import DButton from '../components/elements/DButton'
import Image from 'next/image';
const Footer = dynamic(() => import('../components/Layouts/Footer', { ssr: false }));

import * as Yup from 'yup';
import CallBackForm from '../components/Common/CallBackForm';

export default function Page404({slug = null}) {
  const options = {
    btn_name: "Submit"
  }
  const phoneRegExp = /^(\+\d{1,4})?(\d{9,14})$/
  const fields = [
    {
        name: 'name',
        label: '',
        placeholder: 'Full Name*',
        validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
        customClass: 'md:col-span-6 col-span-12'
    },
    {
        name: 'email',
        type: 'email',
        label: '',
        placeholder: 'Add Your Company E-mail Address*',
        validation: Yup.string().email('Invalid email').required('Email is required'),
        customClass: 'md:col-span-6 col-span-12'
    
    },
    {
        name: 'company_name',
        label: '',
        placeholder: 'Company Name',
        customClass: 'md:col-span-6 col-span-12'
    },
    {
      name: 'phone',
      label: '',
      placeholder: 'Phone Number',
      validation: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      customClass: 'md:col-span-6 col-span-12'
    },
    {
        name: 'message',
        label: '',
        placeholder: 'Message',
        type: 'textarea',
        customClass: 'col-span-12',
        col_row:4
    },
  ];


  return (
    <>
      <Head>
        <title>404 | Delveinsight</title>
        <meta name="description" content="404 Error Page - No page found" />
        <meta name="keywords" content="pharmaceutical company,pharmaceutical industry,pharmaceutical news" />
      </Head>
      
      <Navbar />
      <>
        <section className="bg-gradient-to-r from-blue-100 to-blue-[#1b5df3] py-20">
          <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto ">
            <div className="grid grid-cols-12 gap-4">
              <div className="col-span-12 md:col-span-6">
                <div className="flex flex-col justify-center items-center h-full">
                  <Image priority={false}
                    className="w-auto h-auto"
                    alt="404"
                    src={process.env.IMG_CDN+'img/test/error404-3.png'}
                    width={2000}
                    height={2000}
                  />
                  <h1 className="text-4xl text-center font-semibold my-2 relative">Oh no!</h1>
                  <p className="text-base text-center mt-2 mb-2">The page you are looking for could not be found.</p>
                  <div className='flex'>
                    <Link href={process.env.APP_URL+"/report-store.php"}>
                      <DButton
                        title='Report Store'
                        customClass='bg-red-600'
                      />
                    </Link>
                    <Link href="/">
                      <DButton
                        title='Home'
                      />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-span-12 md:col-span-6">
                <h2 className="text-2xl text-center font-semibold mb-2 uppercase relative">We are sorry the page you are looking is not available</h2>
                <p className="text-base text-center mt-2 mb-4">We request you to fill the query form so that we can help you with your request at the earliest.</p>
                <CallBackForm 
                  fields={fields}
                  options={options}
                  page_name="404 Page"
                  form_name="404-page-form"
                  endpoint="/enquiry/error-form"
                  slug={slug}
                />
              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  )
}