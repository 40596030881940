import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import SectionHeading from '../Common/SectionHeading'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, A11y, Autoplay } from 'swiper/modules'
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

export default function ReportSection({data=null}) {
  return (
    <>
        {(data) &&
            <>
            <div className="w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto lg:my-10 my-6">
                <SectionHeading
                    title="Reports"
                />
                <Swiper
                    className="w-full h-auto"
                    modules={[Scrollbar, A11y, Autoplay]}
                    slidesPerView={2}
                    spaceBetween={50}
                    autoplay={{ delay: 2000 }}
                    loop={true}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 50,
                        },
                        768: {
                            slidesPerView: 5,
                            spaceBetween: 50,
                        },
                        1024: {
                            slidesPerView: 5,
                            spaceBetween: 50,
                        },
                    }}
                    pagination={{ clickable: true }}>
                        {data?.map((item, index) => (
                            <div  key={index}>
                                <SwiperSlide>
                                    <Link href={process.env.APP_URL+item?.link}>
                                        <Image priority={false} 
                                            width={2000} 
                                            height={2000} 
                                            className="w-full h-full" 
                                            alt={item?.altText ? item?.altText : item?.title ? item?.title : 'DelveInsight'} 
                                            src={process.env.IMG_CDN+item?.imageSrc} 
                                        />
                                    </Link>
                                </SwiperSlide>
                            </div>
                        ))}
                </Swiper>
            </div>
            </>
        }
    </>
  )
}
