import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import axios from 'axios';
import HeroBanner from '../Common/HeroBanner';
import Featured from './Featured';
import RecentArticles from './RecentArticles';
const NewsLetterSection = dynamic(() => import('../Common/NewsLetterSection', { ssr: false }))
import { ascoNewsLetterList } from '../Common/DataSet/NewsLetterData';
import NewsletterSectionEvent from '../Conference/Common/NewsLetterSectionEvent';
import { ascoContactList } from '../Common/DataSet/ContactData';
import Downloadable from './DownloadableContent';
import ContactSection from '../Common/ContactSection';
const Breadcrumb = dynamic(() => import('../Layouts/Breadcrumb', { ssr: false }));
import { useGetRandomNewsQuery } from '../Store/Features/NewsletterAPI';
import CommonPopup from '../PopupForm/CommonPopup';
import OurServicesSection from './Common/OurServicesSection';
import { OurServicesList, NewsletterList, CaseStudyList ,CaseStudyListASCO2024, NewsletterListASCO2024} from './DataSet/MainData';
import * as Yup from 'yup';
import CaseStudySection from './Common/CaseStudySection';

const ConferencePages = ({ year, event  }) => {

    useEffect(() => {
        ascoNewsLetterList.click=openModalRFP;
        try {
            const slug_full = (window.location.pathname).split('/').pop();
            axios.get(process.env.API_URL + '/meta/' + slug_full).then((res) => {
                if (res.data?.code == 200) {
                    setMetaData(res.data)
                } 
            }).catch((error) => {

            })
        } catch (err) {
 
        }
    }, [])
    const [DataRendered, setDataRendered] = useState(false);
    useEffect(() => {
        setDataRendered(true)
    }, [])


    const [isModalOpenRFP, setIsModalOpenRFP] = useState(false);
    const openModalRFP = () => {
        setIsModalOpenRFP(true);
    };
    const closeModalRFP = () => {
        setIsModalOpenRFP(false);
    };


    const options = {
        btn_name: "Submit"
    }

    const phoneRegExp = /^(\+\d{1,4})?(\d{9,14})$/

    const fields = [
        {
            name: 'name',
            label: '',
            placeholder: 'Full Name*',
            validation: Yup.string().matches(/^[a-zA-Z\s]*$/, 'Name can only contain letters and spaces').required('Name is required'),
            customClass: 'md:col-span-6 col-span-12'
        },
        {
            name: 'email',
            type: 'email',
            label: '',
            placeholder: 'Add Your Company E-mail Address*',
            validation: Yup.string().email('Invalid email').required('Email is required'),
            customClass: 'md:col-span-6 col-span-12'

        },
        {
            name: 'company_name',
            label: '',
            placeholder: 'Company Name',
            customClass: 'md:col-span-6 col-span-12'
        },
        {
            name: 'phone',
            label: '',
            placeholder: 'Phone Number',
            validation: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
            customClass: 'md:col-span-6 col-span-12'
        }
    ];


    const router = useRouter();
    const { slug } = router.query;

    const yearData = slug && slug.split('-')[2];

    const { data: posts, error, isLoading } = useGetRandomNewsQuery('/conference/filtered_list/asco')
    const [data, setData] = useState([])
    const [featureData, setFeatureData] = useState([])

    const [bannerImage, setBannerImage] = useState('')


    useEffect(() => {
        if (isLoading === false) {

            const filterData = posts?.filter((data, index) => {
                return data.years[0]?.value === year
            })

            const featureData = filterData?.filter((data) => {
                return data?.is_featured === true
            }).slice(0, 6);

            setFeatureData(featureData);
            setData(filterData)


        }

        const imageData = [
            {
                year: 2021,
                image: 'img/events/jpg/asco/asco-event-banner-2021.jpg'
            },
            {
                year: 2022,
                image: 'img/events/png/Asco_banner.png'
            },
            {
                year: 2023,
                image: 'img/events/Asco-2023-Insight-and-Analysis.png'
            },
            {
                year: 2024,
                image: 'img/events/png/asco-banner-2024.png'
            },
        ]

        const imagebG = imageData.filter(data => data.year == year)

        setBannerImage(imagebG[0]?.image ? process.env.IMG_CDN + imagebG[0]?.image : process.env.IMG_CDN + "img/events/jpg/asco/ASCO-2020-Banner.jpg")

    }, [setData, posts, isLoading, year])


    return (
        <>
            <HeroBanner
                background={bannerImage}
            />
            <Breadcrumb />
            <Featured featuredData={featureData} event={event} year={year} slugPrefix={(year != 2020) ? `asco-conference-${year}` : 'asco-conference'} />
            <RecentArticles filterData={data} event={event} year={year} slugPrefix={(year != 2020) ? `asco-conference-${year}` : 'asco-conference'} />
            {/* <ReportSection data={AscoReports} /> */}
            <NewsLetterSection data={ascoNewsLetterList} 
             page_name={`Asco Conference ${year}`} 
             form_name={`asco_conference_${year}`} 
             formTitle={`Asco Conference ${year}`} 
             endpoint={`/enquiry/conference-appointment`}
             extraValues={[{
                key:'conference_name',
                value:`Asco Conference ${year} `
              }]}
             
                />
            <CommonPopup isOpen={isModalOpenRFP} closeModal={closeModalRFP}
                fields={fields}
                options={options}
                page_name="Asco Landing Popup"
                form_name="asco-landing-popup"
                formTitle="We are here to serve you"
                endpoint="/enquiry/conference-appointment"
            />
            <CaseStudySection data={year === '2024' ? CaseStudyListASCO2024 : CaseStudyList} />
            <NewsletterSectionEvent data={year === '2024' ? NewsletterListASCO2024  : NewsletterList} />

            {   event === '/asco' && year <= '2023' ?
                <Downloadable 
                    title={"Downloadable Content ASCO" + " " + year} 
                    year={year}
                />
                :
                ""
            }

            <ContactSection
                data={ascoContactList}
                page_name="ASCO Conference Page"
                form_name="asco-conference-page-form"
                endpoint="/enquiry/conference-expert"
            />
            <OurServicesSection data={OurServicesList} />
        </>
    );
};



export default ConferencePages;
