import React from 'react';
import SectionHeading from '../Common/SectionHeading';
import { limitText } from "../Common/Functions"
import Moment from 'react-moment';
import Link from 'next/link'
import BorderBtn from '../Common/BorderBtn';

const RecentArticles = ({ filterData, year, slugPrefix = "asco-confrence", tail = "all", event }) => {

  const showMoreLimit = 10;

  const [showMore, setShowMore] = React.useState(false);


  const allTargets = [...new Set(filterData?.flatMap(article => article.targets.map(target => target.value)))];
  const allCategories = [...new Set(filterData?.flatMap(article => article.indications.map(indications => indications.value)))];

  const [selectedTags, setSelectedTags] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const handleTagSelection = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter(selectedTag => selectedTag !== tag));
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleCategorySelection = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter(selectedCategory => selectedCategory !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const filteredArticles = filterData?.filter(article => (
    (selectedTags.length === 0 || article.targets.some(target => selectedTags.includes(target.value))) &&
    (selectedCategories.length === 0 || article.indications.some(indication => selectedCategories.includes(indication.value)))
  )).slice(0, 9);

  const visibleCategories = showMore ? allCategories : allCategories.slice(0, showMoreLimit);

  const visibleTarget = showMore ? allTargets : allTargets.slice(0, showMoreLimit);

  return (
    <div className='bg-[#f6f6f6]'>
      <div className='w-11/12 xl:max-w-screen max-w-screen-2xl mx-auto py-8'>
        <SectionHeading 
          title="Our Recent Articles" 
          // shortDescription={event === '/asco' ? "Dive virtually into a kaleidoscope of insights by the ASCO and keep a tab on all the strategic collaborations, significantly growing pipeline, oncology market trends, and the dynamism of the healthcare vertical." : "Dive virtually into a kaleidoscope of insights by the ESMO and keep a tab on all the strategic collaborations, significantly growing pipeline, oncology market trends, and the dynamism of the healthcare vertical."} 
        />
        <div className="grid grid-cols-12 gap-4">
          {/* Tags Filter */}
          <div className="col-span-12 lg:col-span-3">
            <div className='bg-white p-4'>
              <h3 className="text-sm font-semibold pb-2 uppercase text-blue-900">Targets / Technology</h3>
              <hr className='pb-2' />
              <div className="space-y-2">
                {visibleTarget.map(tag => (
                  <div key={tag} className="flex items-start">
                    <input
                      type="checkbox"
                      id={tag}
                      checked={selectedTags.includes(tag)}
                      onChange={() => handleTagSelection(tag)}
                      className="mr-2 mt-1"
                    />
                    <label className="text-sm" htmlFor={tag}>{tag}</label>
                  </div>
                ))}
              </div>
              {allTargets.length > showMoreLimit && (
                <div className="text-center mt-2 text-red-600 text-sm">
                  <div onClick={() => setShowMore(!showMore)}>
                    {showMore ? <b>Show Less <i className="fas fa-caret-up mr-1"></i></b> : <b>Show More <i className="fas fa-caret-down mr-1"></i></b>}
                  </div>
                </div>
              )}
            </div>

            <div className='bg-white p-4 mt-4'>
              <h3 className="text-sm pb-2 font-semibold uppercase text-blue-900">Indications</h3>
              <hr className='pb-2' />
              <div className="space-y-2">
                {visibleCategories.map((category, index) => (
                  <div key={category} className="flex items-start">
                    <input
                      type="checkbox"
                      id={category}
                      checked={selectedCategories.includes(category)}
                      onChange={() => handleCategorySelection(category)}
                      className="mr-2 mt-1"
                    />
                    <label className="text-sm" htmlFor={category}>{category}</label>
                  </div>
                ))}
              </div>
              {allCategories.length > showMoreLimit && (
                <div className="text-center mt-2 text-red-600 text-sm">
                  <div onClick={() => setShowMore(!showMore)}>
                    {showMore ? <b>Show Less <i className="fas fa-caret-up mr-1"></i></b> : <b>Show More <i className="fas fa-caret-down mr-1"></i></b>}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Articles */}
          <div className="col-span-12 lg:col-span-9">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredArticles?.map((article, index) => (
                <div key={index} className="bg-white px-4 pt-4 pb-8 rounded shadow">
                <h3 className='text-md font-semibold'>Title</h3>
                  {/* <div className="text-sm text-gray-600 font-semibold">{article.tags.map((data, index) => {
                    return <p className='my-1' key={index}>{data}</p>
                  })}</div> */}
                  <p className="text-base my-2 line-clamp-2">{article.title}</p>
                  {/* <p className='text-xs'><i className="fas fa-calendar-alt mr-1"></i><Moment format='dddd D MMMM yyyy'>{article.published_on}</Moment></p> */}
                  {(article.executive_summary || article.session) &&
                    <>
                      <h3 className='text-md font-semibold'>Summary</h3> 
                      <p className='text-base my-2 line-clamp-3'  dangerouslySetInnerHTML={{ __html: article.executive_summary ? article.executive_summary : article.session }} ></p>
                    </>
                  } 
                  {/* {
                    (article.abstract) &&
                    <>
                      <h3 className='text-md font-semibold'>Abstract</h3> 
                      <p className='text-base my-2'>{article.abstract ? article.abstract : null}</p>
                    </>
                  } */}
                  <div className='text-center mt-6'>
                  {
                      event === '/asco' ?
                      <BorderBtn 
                        customClass='w-full py-1 bg-[#1b5df3] border-[#1b5df3] text-white rounded'
                        buttonTitle="Go to Article"
                        buttonLink={process.env.APP_URL + "/asco-conference/article/" + article?.slug}
                      />:
                      <BorderBtn 
                        customClass='w-full py-1 bg-[#1b5df3] border-[#1b5df3] text-white rounded'
                        buttonTitle="Go to Article"
                        buttonLink={process.env.APP_URL + "/" + slugPrefix + "/article/" + article?.slug}
                      />
                    }
                  </div>
                </div>
              ))}
            </div>
            <div className='my-4 text-center'><Link href={`/${slugPrefix}/${tail}`}><button className='bg-red-600 px-6 py-1 rounded text-white font-semibold '>VIEW ALL</button></Link></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentArticles;
