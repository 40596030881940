const ascoReportSliderList2023 =[
    {
        imageSrc: 'img/industry/rare-diseases/png/Myelofibrosi.png',
        altText: 'Myelofibrosi',
        link: '/report-store/eosinophilic-asthma-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Progressive-Multifocal.png',
        altText: 'Progressive-Multifocal',
        link: '/report-store/peripheral-arterial-disease-pad-pulmonary-vascular-disease-pvd-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Whim-SyndromeMultifocal.png',
        altText: 'Whim-SyndromeMultifocal',
        link: '/report-store/insomnia-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Wilson-Disease.png',
        altText: 'Wilson-Disease',
        link: '/report-store/tuberculosis-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Atypical-Hemolytic.png',
        altText: 'Atypical-Hemolytic',
        link: '/report-store/acute-lung-injury-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/AL-Amyloidosisand.png',
        altText: 'AL-Amyloidosisand',
        link: '/report-store/asthma-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Adrenal-Crisis.png',
        altText: 'Adrenal-Crisis',
        link: '/report-store/bronchiectasis-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Congenital-Adrenal-Hyperplasia.png',
        altText: 'Congenital-Adrenal-Hyperplasia',
        link: '/report-store/chronic-obstructive-pulmonary-disease-copd-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Autosomal-Dominant.png',
        altText: 'Autosomal-Dominant',
        link: '/report-store/chronic-pulmonary-hypertension-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Focal-Segmental.png',
        altText: 'Focal-Segmental',
        link: '/report-store/chronic-thromboembolic-pulmonary-hypertension-cteph-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Dystrophic-Epidermolysis.png',
        altText: 'Dystrophic-Epidermolysis',
        link: '/report-store/idiopathic-pulmonary-fibrosis-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Fabry-Disease.png',
        altText: 'Fabry-Disease',
        link: '/report-store/interstitial-lung-disease-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Developmentals.png',
        altText: 'Developmentals',
        link: '/report-store/bronchial-neoplasm-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Cystic-Fibrosis.png',
        altText: 'Cystic-Fibrosis',
        link: '/report-store/chronic-pulmonary-infections-due-to-pseudomonas-aeruginosa-in-patients-with-cystic-fibrosis-market',
    },
    {
        imageSrc: 'img/industry/rare-diseases/png/Wilson-Disease.png',
        altText: 'Featured Newsletter',
        link: '/whitepaper-newsletter/pd1-pdl1-inhibitors-whitepaper',
    },
]
const ascoReportSliderList2022 = [
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/BRAF_Mutated_Non-small_Cell Lung_Cancer (NSCLC).png',
        altText: 'BRAF Mutated Non-small Cell',
        link: '/eport-store/braf-mutated-non-small-cell-lung-cancer-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Castration-Resistant_Prostate_Cancer_(CRPC).png',
        altText: 'Castration',
        link: '/report-store/castration-resistant-prostate-cancer-crpc-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Epidermal Growth_Factor_Receptor-Non_Small_Cell_Lung_Cancer_(EGFR-NSCLC).png',
        altText: 'Epidermal Growth',
        link: '/report-store/epidermal-growth-factor-receptor-non-small-cell-lung-cancer-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/ESR1_Mutated_Metastatic_Breast_Cancer.png',
        altText: 'ESR1 Mutated',
        link: '/report-store/esr1-mutated-metastatic-breast-cancer-epidemiology-forecast',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Extensive-Stage_Small_Cell_Lung_Cancer_(ESCLC).png',
        altText: 'Extensive-Stage_Small_Cell',
        link: '/report-store/extensive-stage-small-cell-lung-cancer-esclc-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Follicular_Lymphoma.png',
        altText: 'Follicular',
        link: '/report-store/follicular-lymphoma-market-insightst',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/HER2-Positive_Breast_Cancer.png',
        altText: 'HER2-Positive',
        link: '/report-store/her2-positive-breast-cancer-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Hormone_Sensitive_Advanced_Prostate_Cancer.png',
        altText: 'Hormone_Sensitive',
        link: '/report-store/hormone-sensitive-advanced-prostate-cancer-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Metastatic_Castration-Resistant_Prostate_Cancer_(mCRPC).png',
        altText: 'Metastatic',
        link: '/report-store/metastatic-castration-resistant-prostate-cancer-mcrpc-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Metastatic_Hormone_Refractory Prostate_Cancer.png',
        altText: 'Metastatic Hormone Refractory',
        link: '/report-store/metastatic-hormone-refractory-prostate-cancer-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Metastatic_Prostate_Cancer.png',
        altText: 'Metastatic Prostate',
        link: '/report-store/metastatic-prostate-cancer-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Metastatic_triple-negative_breast_cancer_(mTNBC).png',
        altText: 'Metastatic triple-negative breast',
        link: '/report-store/metastatic-triple-negative-breast-cancer-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Non-Small_Cell_Lung_Cancer.png',
        altText: 'Non Small',
        link: '/report-store/non-small-cell-lung-cancer-nsclc-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Programmed_death-ligand 1_(PD-L1)_Non-small _Cell_Lung_Cancer_(NSCLC).png',
        altText: 'Programmed death',
        link: '/report-store/programmed-death-ligand-1-pd-l1-non-small-cell-lung-cancer-nsclc-market',
    },
    {
        imageSrc: 'img/events/jpg/asco-2022/ascoreports/Prostate _Cancer.png',
        altText: 'Prostate Cancer',
        link: '/report-store/prostate-cancer-market-insight',
    },
]

const AscoReports = {
    'asco-conference-2023': ascoReportSliderList2023,
    'asco-conference-2022': ascoReportSliderList2022,
}

export {
    AscoReports
}