import React, { useEffect, useState } from 'react';
import AscoEventLanding from '../components/Conference/AscoEventLanding';
import EsmoEventlanding from '../components/Conference/EsmoEventLanding';
import { useRouter } from 'next/router';
import ErrorPage from "./404"
import Navbar from "../components/Layouts/Navbar"
import Footer from "../components/Layouts/Footer"
import axios from 'axios'

const Conference = ({ }) => {
  const [data, setData] = useState(null);
  const [baseUrl, setBaseUrl] = useState("")

  const router = useRouter();
  
  useEffect(() => {
    const fetchData = async () => {
      const allowedSlugs = ['/asco-conference-2024','/asco-conference-2023', '/asco-conference-2022',
        '/asco-conference-2021', '/asco-conference', '/esmo-conference-2023','/esmo-conference-2024',
        '/esmo-conference-2022', '/esmo-conference-2021', '/esmo-conference-2020'];

      const currentUrl = window.location.pathname;
      setBaseUrl(currentUrl)
      if (!allowedSlugs.includes(currentUrl)) {
        return router.push('/404');
      }

      const yearMatch = currentUrl.match(/\d{4}/);
      const fetchedYear = yearMatch?.[0] ? yearMatch[0] : '2020';

      const parts = currentUrl.split("-");
      const fetchedEvent = parts[0];

      setData({
        event: fetchedEvent,
        year: fetchedYear,
      });
    };

    fetchData();
  }, [router]);
  return (
    <>
      <Navbar />
      {data ? (data?.event) === '/asco' ?
        <AscoEventLanding year={data.year} event={data.event} /> :
        (data?.event) === '/esmo' ?
          <EsmoEventlanding year={data.year} event={data.event} /> :
          <ErrorPage /> :
        <>
          <div className="flex justify-center items-center min-h-screen">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
          </div>
        </>
      }
      <Footer />
    </>
  );
};

Conference.getInitialProps = async (NextPageContext) => {
    const slugData = NextPageContext.query.conference
  
    try {
        const response = await axios.get(process.env.API_URL + '/meta/' + slugData);
        return {
            title: (response?.data.meta_title) ? response?.data.meta_title : "Healthcare Market Research Reports & Consulting Firm | DelveInsight",
            description: (response?.data.meta_description) ? response?.data.meta_description : "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies.",
            keywords: (response?.data.meta_keywords) ? response?.data.meta_keywords : "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends.",
        }
    } catch (error) {
        console.error('Error fetching data:', error.message);
        return {
            title: "Healthcare Market Research Reports & Consulting Firm | DelveInsight",
            description: "DelveInsight is a pioneer in Healthcare Market Research Reports & Consulting Services. We advise Pharma leaders on making strategic decisions across all therapy areas & geographies.",
            keywords: "pharmaceutical business consulting, market research company, pharma consulting, biotech consulting, pharma market research, pharma market insights, biotech consulting,  pharma consulting service, pharma leading, market trends.",
        };
    }
  }

export default Conference;
