import { useRouter } from 'next/router';
import React, { useState, useEffect } from 'react'
import dynamic from 'next/dynamic'
import HeroBanner from '../Common/HeroBanner';
import Featured from './Featured';
import RecentArticles from './RecentArticles';
import ReportSection from './ReportSection';
import { ascoReportSliderList } from './DataSet/ReportData';
import NewsLetterSectionEvent from './Common/NewsLetterSectionEvent';

const Breadcrumb = dynamic(() => import('../Layouts/Breadcrumb', { ssr: false }));
import { useGetRandomNewsQuery } from '../Store/Features/NewsletterAPI'
import FeaturedSection from './Common/FeaturedSection';
import ScheduleAppointment from './ScheduleAppointment';
import OurServicesSection from './Common/OurServicesSection';
import { OurServicesList, NewsletterList, CaseStudyList, CaseStudyListEsmo2024, NewsletterListEsmo2024, OurServicesListEsmo2024 } from './DataSet/MainData';
import CaseStudySection from './Common/CaseStudySection';

const ConferencePage = ({ year, event, }) => {

    const router = useRouter();
    const { slug } = router.query;

    const yearData = slug && slug.split('-')[2];

    const { data: posts, error, isLoading } = useGetRandomNewsQuery('/conference/filtered_list/esmo')
    const [data, setData] = useState([])
    const [featureData, setFeatureData] = useState([])

    const [bannerImage, setBannerImage] = useState('')



    useEffect(() => {
        if (isLoading === false) {

            const filterData = posts?.filter((data, index) => {
                return data.years[0]?.value === year
            })

            const featureData = filterData?.filter((data) => {
                return (data?.is_featured === true)
            }).slice(0, 6);

            setFeatureData(featureData);
            setData(filterData)


        }

        const imageData = [
            {
                year: 2021,
                image: 'img/events/jpg/esmo/esmo-event-banner.jpg'
            },
            {
                year: 2022,
                image: 'img/events/jpg/esmo-2022/Esmo-main-banner.jpg'
            },
            {
                year: 2023,
                image: 'img/events/jpg/esmo-2023/esmo-2023-banner.webp'
            },
            {
                year: 2024,
                image: 'conference/esm-banner.png'
            }
        ]

        const imagebG = imageData.filter(data => data.year == year)

        setBannerImage(imagebG[0]?.image ? process.env.IMG_CDN + imagebG[0]?.image : process.env.IMG_CDN + "img/events/jpg/esmo/ESMO-Main-Banner.jpg")

    }, [setData, isLoading, posts, year])


    return (
        <>

            {
                (year == 2020 || year == 2021) ? <HeroBanner
                    background={bannerImage}
                /> : <HeroBanner
                    background={bannerImage}
                    title={<>Delveinsight&apos;s Extensive Coverage of <br />

                        <span>ESMO {year} Conference</span></>}
                />
            }


            <Breadcrumb />
            <Featured featuredData={featureData} year={year} slugPrefix={`esmo-conference-${year}`} />

            {/* <FeaturedSection featuredData={featureData} year={year} customClass="rounded-none"/> */}
            <RecentArticles filterData={data} year={year} slugPrefix={`esmo-conference-${year}`} />
            <ReportSection data={ascoReportSliderList} />
            <CaseStudySection data={year === '2024' ? CaseStudyListEsmo2024 : CaseStudyList} />
            <NewsLetterSectionEvent data={year === '2024' ? NewsletterListEsmo2024  : NewsletterList} />
            <ScheduleAppointment title="Schedule Your Appointment" />
            <OurServicesSection data={year === '2024' ? OurServicesListEsmo2024 : OurServicesList} />
        </>
    );
};

export default ConferencePage;
